@import url('https://fonts.googleapis.com/css2?family=M+PLUS+Rounded+1c:wght@100;300;400;500;700;800;900&display=swap');


.faq-header {
    color: #000;
    font-size: 30px;
    display: flex;
    justify-content: center;
    font-family: 'M PLUS Rounded 1c', sans-serif;
    font-style: normal;
    font-weight: 600;
}



.accordion-header {
    font-family: 'M PLUS Rounded 1c', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 29px;
    color: #000000;
    text-align: left;
}

.accordion-body {
    font-family: 'M PLUS Rounded 1c', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 29px;
    color: #000000;
}

.accordion-con {
    margin-top: 30px;
}


.faqNav {
    background-color: #024512;
    border-radius: 5vw;
    
}