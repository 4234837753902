.companyLogo  {
    width: 9vh;
    height: 8.5vh;
    object-fit: fill;
    border-radius: 50%;
    border-bottom: 3px solid #0c0c0c33;
}

body {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
  }

  @media only screen and (max-width: 600px) {
    .navBAR {
        width: 100% !important;
    }
  }

  @media (min-width: 700px) and (max-width: 900px) {
    .companyLogo {
       height: 9vh;
       margin-top: 2vh;
    }
  }

  @media screen and (min-width: 910px) {
    .companyLogo {
        border-bottom: none;
    }
  }