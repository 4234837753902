@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800&family=M+PLUS+Rounded+1c:wght@100;300;400;500;700;800;900&family=Poppins:wght@300;400;500&display=swap');


.map-container {
    width: 100%;
    height: 70vh;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto; /* Centers the div horizontally */
  }
  

  .PhoneInputInput {
    flex: 1 1;
    min-width: 0;
    height: 5vh; /* Adjust height as needed */
}

/* Styling for the input field similar to MUI's outlined input */
.PhoneInputInput input {
    border-color: #86c350; /* Default border color */
    border-width: 1px;
    border-radius: 4px;
}

/* Hover and focus states */
.PhoneInputInput input:hover {
    border-color: #86c350;
    border-width: 2px;
}

.PhoneInputInput input:focus {
    border-color: #86c350;
    border-width: 2px;
}

/* MUI-specific focus hover states if applicable */
.PhoneInputInput input:focus:hover {
    border-color: #86c350;
    border-width: 2px;
}

