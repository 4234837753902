body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    background: #FFFFFF;
}

.servicesIcons span {
    color: #86C350;
    font-size: 4rem;
}


.text h6 {
    color: #fff;
    font-family: Outfit, sans-serif;
    font-weight: 500;
    text-align: left;
}

.text Button {
    border: #fff;
    text-align: center;
    background: #2EAE6D;
    color: #fff;
    white-space: nowrap;
}

.text Button:hover {
    background-color: #7BC24F;
    text-align: center;
    color: #fff;
    padding: 1.3vw;
    transition: 0.2s;
    box-shadow: 0 0.5em 0.5em 0 rgba(0, 0, 0, 0.5);
}

.image-slide img {
    /* width: 60%; */
    height: 50vh;
    margin-top: 6%;
    object-fit: cover;
}


.values img {
    width: 70%;
    height: 80%;
}

.values span {
   font-size: 3em;
}


/* Slideshow */
.slideshow {
    margin: 2% 4%;
    overflow: hidden;
    max-width: 500px;
  }
  
  .slideshowSlider {
    white-space: nowrap;
    transition: ease 1000ms;
  }
  
  .slide {
    display: inline-block;
    max-height: 400px;
    width: 100%;
    border-radius: 1vh;
  }
  
  /* Buttons */
  .slideshowDots {
    text-align: center;
  }
  
  .slideshowDot {
    display: inline-block;
    height: 1.2vh;
    width: 2%;
    border-radius: 50%;
    cursor: pointer;
    margin: 15px 7px 0px;
    background-color: #fff;
  }
  
  .slideshowDot.active {
    background-color: #2EAE6D;
  }
  


/* Services hover effects */
.container {
    width: 12vw;
    height: 30vh;
}


.container:hover > .card1 {
    cursor: pointer;
    transform: rotateY(180deg);
}

.card1 {
    height: 90%;
    width: 100%;
    position: relative;
    transition: transform 1500ms;
    transform-style: preserve-3d;
}

.front1 img {
    width: 100%;
    height: 70%;
    border-radius: 1rem;
    padding: 0.5vw;
    max-width: 300px;
} 

.front1 {
    background-color: #008548;
    display: flex;
    justify-content: center;
    max-width: 310px;
    align-items: center;
    
}

.front1, 
.back1 {
    height: 100%;
    width: 100%;
    border-radius: 1rem;
    box-shadow: 0 0 5px 2px rgba(50, 50, 50, 0.25);
    position: absolute;
    backface-visibility: hidden;
}

.back1 {
    background-color: #008548;
    transform: rotateY(180deg);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.contact span {
    color: #4EA908;
}

.emailLinks a {
    text-decoration: none;
    color: #000;
}

.emailLinks a:hover {
    color: #7ACA33;
}

@media screen and (min-width: 330px) {
    #description {
        margin-left: 8vw;
    }
}

@media screen and (min-width: 400px) {
    #description {
        margin-left: -0.5vw;
    }
    
}

