@import url('https://fonts.googleapis.com/css2?family=M+PLUS+Rounded+1c:wght@100;300;400;500;700;800;900&display=swap');

body {
    box-sizing: border-box;
    padding: 0;
    margin: 0 !important;
    width: fit-content;
}

html {
    box-sizing: border-box;
    padding: 0;
    margin: 0 !important;
    width: fit-content;
}


* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
  }

form {
    width: 50%;
    margin-top: 6vh;
}

.contactNav {
    background-color: #024512;
    border-radius: 50%;
}

