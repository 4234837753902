body {
    margin: 0;
    padding: 0;
}

.section {
    background-image: url('../../assets/images/storageBackground.png');
    background-repeat: no-repeat;
    background-size: cover; 
    background-position: center;
    height: 1200px;
    width: 95vw;
    margin: 0;
    padding: 0;
}