.products-box {
    display: flex;
    background-color: #fff;
    flex-wrap: wrap;
    justify-content: center;
}


.back-arr {
    position: absolute;
    left: 0;
    margin-left: 121px;
    top: 0px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 29px;
    color: #000000;
    padding-top: 10px;
    text-decoration: none;
}

.item {
    background-color: #fff;
    width: 233px;
    height: 330px;
    margin: 90px 30px;
    box-sizing: border-box;
    border: 2px solid #707070;
    backdrop-filter: blur(4px);
    /* Note: backdrop-filter has minimal browser support */
    border-radius: 6px;
}

.item img {
    margin: 0 7px;
    width: 214px;
    height: 200px;
    /* border: 1.5px solid #000; */
    margin-top: 10px;
}

.item h2 {
    color: #000;
    display: flex;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    justify-content: center;
    line-height: 20px;
    margin-top: 15px;
    max-width: 200px;
    margin-left: 20px;
}

.item-button {
    display: flex;
    background-color: #fff;
    text-decoration: none;
    border: #000 1px solid;
    border-radius: 4px;
    height: 30px;
    width: 210px;
    margin: 13px auto;
}

.item p {
    margin-top: 3px;
    margin-bottom: 6px;
    margin-left: 52px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.4px;
    text-transform: uppercase;
    color: #000000;
}
