
.ablutionsContainer {
    background: #024512;
    min-height: 90vh;
    position: relative;
    overflow: hidden;
    border-radius: 0.6vw;
    width: 100%;
    margin: 0;
}

.content-container {
    display: flex;
    justify-content: center;
    background-color: #000;
    color: #fff;
}

.image-container img {
    width: 30vw;
    height: 40vh;
    object-fit: cover;
    margin-bottom: 3vh;
    border-radius: 3px;
    border: #fff solid 0.4vh;
    padding: 1vh;
}

.image-container img:hover {
    padding: 0vh;
    border: #7BC24F solid 0.5vh;
    width: 33vw;
    transition: ease 400ms;
}


@media (max-width: 1024px) {

    .image-container img {
    width: 80%;
    height: 80%; /* You can adjust this value to your preference */
    object-fit: cover; /* You can use other values like 'contain' or 'fill' */
    pointer-events: none;
}
}